<template>
    <div v-if="$hasPermissions(clientSession, ['CLIENT_REPORTS'], 1)">
      <vue-headful :title="pageTitle" />
      <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">{{ pageheading.toLocaleUpperCase() }}</h1>
      </div>
      <div style="max-width: 95%; margin: auto;">
        <div v-show="isLoading">
          <Loading />
        </div>
        <div style="padding: 4px; height: 100%; width: 100%!important;">
  
          <!-- Filters Section -->
          <div class="exception-filters-section">
            <div class="columns is-multiline is-desktop is-fullwidth">
              <div class="column is-full-mobile is-narrow">
                <b-field label="Year">
                  <input
                    v-model="filters.selectedYear"
                    type="number"
                    placeholder="Enter year"
                    min="1900"
                    max="2100"
                    class="input"
                    style="max-width: 132px;"
                  />
                </b-field>
              </div>
              <div class="column is-full-mobile is-one-fifth-desktop">
                <b-field label="Carrier">
                  <multiselect
                    v-model="filters.selectedCarriersArray"
                    :options="carriers"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="All Carriers"
                    :loading="carriersLoading"
                    @select="fetchClients"
                    @remove="fetchClients"
                    label="label"
                    track-by="value">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-show="values.length > 0 && !isOpen">{{ values.length }} carriers selected </span>
                    </template>
                    <template slot="clear">
                      <div class="multiselect__clear" v-if="filters.selectedCarriersArray.length" @mousedown.prevent.stop="() => (filters.selectedCarriersArray = [])">
                        <i class="fas fa-times"></i>
                      </div>
                    </template>
                  </multiselect>
                </b-field>
              </div>
              <div class="column is-full-mobile">
                <b-field label="Client">
                  <multiselect
                    v-model="filters.selectedClientsArray"
                    :options="clients"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :loading="clientsLoading"
                    :preserve-search="true"
                    placeholder="All Clients"
                    label="label"
                    track-by="value">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single"  v-show="values.length > 0 && !isOpen">{{ values.length }} clients selected</span>
                    </template>
                    <template slot="clear">
                      <div class="multiselect__clear" v-if="filters.selectedClientsArray.length" @mousedown.prevent.stop="() => (filters.selectedClientsArray = [])">
                        <i class="fas fa-times"></i>
                      </div>
                    </template>
                  </multiselect>
                </b-field>
              </div>
              <div class="column is-full-mobile is-narrow">
                <b-field label="Policy Values">
                  <b-select v-model="filters.hasPolicyValues" placeholder="Select Policy Values">
                    <option v-for="value in policyValues" :key="value.value" :value="value.value">
                      {{ value.text }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-full-mobile is-narrow is-flex is-align-items-bottom">
                <div class="control is-flex" style="align-items: end; padding-bottom: 6px;">
                  <button class="button is-accent" @click="applyFilters">View Report</button>
                </div>
              </div>
            </div>
          </div>
          <Grid
            v-if="!isLoading"
            class="exception-grid header-1st-row-summary header-2nd-row-summary"
            :data-items="beginningYearBalance.value"
            :sortable="true"
            :resizable="true"
            :pageable="{
              pageSizes: [10, 20, 50, 100]
            }"
            :columns="columns"
            :page-size="pagination.pageSize"
            :skip="pagination.skip"
            :total="beginningYearBalance.count"
            @pagechange="onPageChange"
            @sortchange="sortChangeHandler"
            :sort="sort"
            :style="{ maxHeight: 'none', height: 'auto' }"
            :row-render="rowRenderFn"
          >
          <template v-slot:CurrencyCell="{ props }">
            <td style="text-align: right;">
              {{ formatCurrency(props.dataItem[props.field]) }}
            </td>
          </template>
          <template v-slot:PercentCell="{ props }">
            <td style="text-align: right;">
              {{ (props.dataItem[props.field]) }} %
            </td>
          </template>
          </Grid>
        </div>
      </div>
    </div>
    <div v-else>
      <vue-headful :title="pageTitle" />
      <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
        <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">No Permission</h1>
      </div>
      <div class="has-text-centered ">
        <b>You Lack The Permissions Required To View This Page</b>
      </div>
    </div>
  </template>
<script>
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import Loading from '../Loading'
import moment from 'moment'
import { Grid } from '@progress/kendo-vue-grid';
  
const pageName = 'Beginning Year Balance Comparison';
  
export default {
  components: {
    Loading,
    Grid,
  },
  data () {
    return {
      useSimpleReport: true,
      pageheading: pageName,
      isLoading: true,
      carriersLoading: true,
      clientsLoading: true,
      beginningYearBalance: {
        summary: {},
        count: 0,
        value: []
      },
      carriers: [],
      clients: [],
      sort: [{ field: 'clientID', dir: 'asc' }],
      filters: {
        selectedClientsArray: [],
        selectedCarriersArray: [],
        hasPolicyValues: 0,
        selectedYear: new Date().getFullYear()
      },
      policyValues: [
        { text: 'All Values', value: 0 },
        { text: 'No Exception', value: 1 },
        // { text: 'Partial Exception', value: 2 },
        { text: 'Exception', value: 3 }
      ],      
      pagination: {
        take: 10,
        skip: 0,
        total: 0,
        pageSize: 10
      },
      dateString: null
    }
  },
  methods: {
    rowRenderFn: function (h, trElement, defaultSlots, props) {
      const error = props.dataItem.error;

      const errorStyle = {
        color: 'red'
      };

      const trProps = {
        style: error ? errorStyle : '',
      };

      return h(
        'tr',
        trProps,
        defaultSlots);
    },       
    getPxWidth(size) {
      const defaultFontSize = 16;
      return size * defaultFontSize * 0.6;
    },
    applyFilters () {
      this.fetchBeginningYearCountMatchReport()
    },
    formatCurrency (value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value)
    },
    getFormattedDate (date) {
      return new Date(date).toLocaleDateString('en-US')
    },
    async fetchBeginningYearCountMatchReport () 
    {
      this.isLoading = true
      try {
        const params = {
          year: this.filters.selectedYear,
          carrierFilter: this.selectedCarriers,
          clientFilter: this.selectedClients,
          stateFilter: this.filters.hasPolicyValues,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortField: this.sortObject.field,
          sortDirection: this.sortObject.dir
        }
  
        const res = await this.api().getBeginningYearBalanceReport(params);

        if (!res.isInputError) {
          this.beginningYearBalance = res
          this.beginningYearBalance.summary = this.beginningYearBalance.summary || {}
          if (this.beginningYearBalance.summary != null) this.filters.selectedYear = this.beginningYearBalance.summary.year
        }
      } catch (error) {
        console.error('Error fetching client exceptions:', error)
        this.errorToast('Failed to fetch client exceptions.')
      }
      this.isLoading = false
    },
    async fetchClients () {
      this.clientsLoading = true
      try {
        const result = await this.api().getClientsToPolicies()
        this.clients = result.map(client => ({
          value: client.id,
          label: client.id + ' - ' + client.name
        }))
      } catch (error) {
        console.error('Error fetching clients:', error)
        this.errorToast('Failed to fetch clients.')
      }
      this.clientsLoading = false
    },
    async fetchCarriers () {
      this.carriersLoading = true
      try {
        var result = await this.api().getCarriers()
        this.carriers = result.map(carrier => ({
          value: carrier.code,
          label: carrier.code
        }))
      } catch (error) {
        console.error('Error fetching carriers:', error)
        this.errorToast('Failed to fetch carriers.')
      }
      this.carriersLoading = false
    },
    onPageChange ({ page }) {

      if (this.pagination.pageSize !== page.take) {
        this.pagination.pageSize = page.take
      }

      this.pagination.skip = page.skip
      this.pagination.pageSize = page.take
      this.pagination.page = (page.skip / page.take) + 1

      this.fetchBeginningYearCountMatchReport()
    },
    sortChangeHandler ({ sort }) {
      this.sort = sort
      this.fetchBeginningYearCountMatchReport()
    },
    customDateParser (dateString) {
      const date = moment.utc(dateString, 'YYYY-MM-DD', true)
      return date.isValid() ? date.toDate() : null
    },
    customDateFormatter (date) {
      return date ? moment.utc(date).format('YYYY-MM-DD') : ''
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    selectedClients () {
      return this.filters.selectedClientsArray.length > 0
        ? this.filters.selectedClientsArray.map(client => client.value).join(',') : ''
    },
    selectedCarriers () {
      return this.filters.selectedCarriersArray.length > 0
        ? this.filters.selectedCarriersArray.map(carrier => carrier.value).join(',') : ''
    },
    sortObject () {
      let sort = { field: 'clientID', dir: 'asc' }
      if (this.sort.length === 1) {
        sort = this.sort[0]
      }
      return sort
    },
    formattedDate: {
      get () {
        return this.dateString ? new Date(this.dateString) : null
      },
      set (value) {
        if (value) {
          const year = value.getFullYear()
          const month = String(value.getMonth() + 1).padStart(2, '0') // month is zero-indexed
          const day = String(value.getDate()).padStart(2, '0')
          this.dateString = `${year}-${month}-${day}`
        } else {
          this.dateString = null
        }
      }
    },
    columns () {
      return [
        {
          title: `${this.$formatNumber(this.beginningYearBalance?.summary?.totalUniqueClients || 0)} Clients`,
          children: [
            {
              field: 'clientId',
              title: 'Client ID',
              width: this.getPxWidth(4 + 8),
              sortable: true
            },
            {
              field: 'clientName',
              title: 'Client Name',
              headerClassName: 'is-flex-grow-1',
              className: 'is-flex-grow-1',
              sortable: true,
            }
          ]
        },     
        {
          title: `${this.$formatNumber(this.beginningYearBalance?.summary?.totalUniqueCarriers || 0)} Carriers`,
          headerClassName: 'carrierCodeClass',
          children: [
            {
              field: 'carrierCode',
              title: 'Carrier',
              width: this.getPxWidth(3 + 8),
              sortable: true
            }
          ]
        },
        {
          title: `${this.$formatCurrency(this.beginningYearBalance?.summary?.totalSumJanuaryBeginningBalance || 0, 0)}`,
          headerClassName: 'has-text-right',
          children: [{
            field: 'sumJanuaryBeginningBalance',
            title: 'January Beginning Year Balance',
            sortable: true,
            width: this.getPxWidth(6 + 20),
            cell: 'CurrencyCell'
          }]
        },
        {
          title: `${this.$formatCurrency(this.beginningYearBalance?.summary?.totalSumJanuaryCurrentBalance || 0, 0)}`,
          headerClassName: 'has-text-right',
          children: [{
            field: 'sumJanuaryCurrentBalance',
            title: 'Current Beginning Year Balance',
            sortable: true,
            width: this.getPxWidth(4 + 25),
            cell: 'CurrencyCell'
          }]
        }
      ]
    }
  },
  mounted () {
    this.pagination.pageSize = 15;
    this.fetchCarriers();
    this.fetchClients();
    this.fetchBeginningYearCountMatchReport();
  }
}
</script>
  <style lang="scss">
    @import '@/styles/exception-reporting.scss';
  </style>
